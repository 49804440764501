<script setup lang="ts">
import type { UIQuantityPickerProps } from './UIQuantityPicker.props'

const props = withDefaults(defineProps<UIQuantityPickerProps>(), {
  disableAdd: false,
  disableDecrease: false,
  availableProductStock: true,
  loading: false,
})

const emit = defineEmits<{
  decrease: []
  increase: []
}>()

const handleDecrease = () => {
  if (!props.availableProductStock) return
  emit('decrease')
}

const handleIncrease = () => {
  if (!props.availableProductStock) return
  emit('increase')
}
</script>

<template>
  <div class="flex flex-row items-center gap-x-2">
    <button
      type="button"
      :aria-label="$ts('accessibility.decreaseQuantity')"
      :disabled="disableDecrease"
      :class="[
        { 'opacity-20': disableDecrease },
        { '!cursor-default opacity-20': !availableProductStock },
      ]"
      @click.stop="handleDecrease"
    >
      <DefaultIconsRemove aria-hidden="true" />
    </button>
    <div class="icon-isloading-container">
      <DefaultIconsLoader v-if="loading" aria-hidden="true" class="h-4 w-4" />
      <span v-else data-testid="quantity-picker-quantity">
        <slot />
      </span>
    </div>
    <button
      type="button"
      :aria-label="$ts('accessibility.increaseQuantity')"
      :disabled="disableAdd"
      :class="[
        { 'opacity-20': disableAdd },
        { '!cursor-default opacity-20': !availableProductStock },
      ]"
      data-testid="quantity-picker-increase-button"
      @click.stop="handleIncrease"
    >
      <DefaultIconsAdd aria-hidden="true" />
    </button>
  </div>
</template>

<style lang="scss" scoped>
button {
  @apply border-primitives-black text-primitives-black h-4 w-4 p-0;

  svg {
    @apply h-full w-full;
  }
}
.icon-isloading-container {
  @apply flex h-4 w-4 items-center justify-center;
  position: relative;
}

.icon-isloading-container > * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
